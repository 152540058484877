import React, { useContext } from "react";
import { GlobalDataContext } from "../../context/context";
import { ButtonContent } from "../global/boton/ButtonContent";
function HeroVideo({ urlVideo }) {
    const { rpdata } = useContext(GlobalDataContext);
    return (
        <section className={'svgBoxRectanguloBottonRigth'}>
            <div className="w-full relative content_video svgHerosection2 flex justify-center">
                <video

                    playsInline
                    autoPlay
                    muted
                    loop
                    className="w-full md:h-full object-cover"
                >
                    <source
                        src={urlVideo}
                        type="video/mp4"
                    />
                </video>
                <div className="flex md:flex-row flex-col md:w-4/5 mx-auto absolute bottom-[100px] md:bottom-[100px] lg:bottom-[120px] md:text-start text-center text-white">
                    <div className="w-full text-center p-4">
                        <h1 className="text-[30px] md:text-[3em] lg:text-[4em]">{rpdata?.dbSlogan?.[0].slogan}</h1>
                        <p className="px-5 md:px-[20%]">{rpdata?.dbValues?.[0].description}</p>
                        <ButtonContent btnStyle="three" />
                    </div>
                </div>
            </div>
            <div className="absolute md:-bottom-[200px] -bottom-[138px] md:right-[100px] right-[30%] md:h-[250px] md:w-[250px] h-[150px] w-[150px]">
                <img
                    src={"https://firebasestorage.googleapis.com/v0/b/imagenes-fc412.appspot.com/o/Años%20de%20experiencia%2F22_Mesa%20de%20trabajo%201.png?alt=media&token=e768a267-2d67-4d44-b423-d9efc2a09e49"}
                    alt={'images'}
                    className=' w-full h-full object-cover'
                />

            </div>
        </section>
    );
}
export default HeroVideo;